<template>
    <div class="box tabbed">
        <h2>E-Mail Black- / Whitelist</h2>
        <ul class="tabs">
            <li v-for="list in emaillists" :class="{ active: list.context == activeTab }" v-bind:key="list.context" @click="activeTab = list.context">
                <i v-if="list.context == 'prod'" class="fal fa-tachometer-alt-slow"></i>
                <i v-if="list.context != 'prod'" class="fal fa-tachometer-alt-fastest"></i>
                <br />

                {{ list.context }}
            </li>
        </ul>
        <div class="body">
            <div v-for="list in emaillists" v-bind:key="list.context" :class="{ active: list.context == activeTab }" data-tab-content>
                <input type="radio" value="blacklist" v-model="list.type" :id="'type_black_' + list.context" />
                <label :for="'type_black_' + list.context"> Blacklist</label>
                <br />
                <input type="radio" value="whitelist" v-model="list.type" :id="'type_white_' + list.context" />
                <label :for="'type_white_' + list.context"> Whitelist</label>

                <br /><br />

                <table>
                    <thead>
                        <tr>
                            <th>Eintrag</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(subject, index) in list.subjects" v-bind:key="index">
                            <td>
                                <div class="form-group form-float no-error">
                                    <input v-model="list.subjects[index]" :id="'entry_' + list.env.env + '_' + index" placeholder=" " type="text" />
                                    <label :for="'entry_' + list.env.env + '_' + index">Eintrag (* als Platzhalter)</label>
                                </div>
                            </td>
                            <td><i @click="removeSubject(index, list)" class="delete fal fa-trash-alt"></i></td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <span class="add" @click="addSubject(list)"><i class="add fal fa-plus-circle"></i> Hinzufügen</span>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <hr />

                <div class="button-row">
                    <button type="button" @click="save(list)" class="primary size-l"><i class="fal fa-save"></i> Speichern</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            activeTab: "prod",
            emaillists: []
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.$api.get("/emaillists").then(
                response => {
                    this.emaillists = response.data.data;
                    for (let i = 0; i < this.emaillists.length; i++) {
                        if (this.emaillists[i].subjects === null) {
                            this.emaillists[i].subjects = [];
                        }
                    }
                },
                () => {
                    this.$snotify.error("Fehler beim Laden der Emaillisten");
                }
            );
        },
        addSubject(list) {
            list.subjects.push("");
        },
        removeSubject(index, list) {
            list.subjects.splice(index, 1);
        },
        save(list) {
            var request = {
                env: list.env.env,
                type: list.type,
                subjects: list.subjects
            };

            this.$api.post("/emaillist", request).then(
                () => {
                    this.$snotify.success("Speichern erfolgreich");
                },
                () => {
                    // Save failed
                    this.$snotify.error("Speichern fehlgeschlagen");
                }
            );
        }
    }
};
</script>
